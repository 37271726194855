<template>
  <v-row justify="center">
    <v-col cols="auto">
      <v-dialog
        v-model="dialog"
        transition="dialog-bottom-transition"
        max-width="800"
      >
        <v-card :style="{ background: '#F7FAFC' }">
          <v-toolbar class="bg-test" dark>Add User</v-toolbar>
          <v-card class="mx-5 my-8 py-4 px-5">
            <div class="row d-flex align-items-center">
              <div class="col-12">
                <span>Employee ID</span>
                <v-text-field
                  class="mt-4"
                  fab
                  type="text"
                  v-model="userData.employee_code"
                ></v-text-field>
              </div>

              <div class="col-6">
                <span>User Email</span>
                <v-text-field
                  class="mt-4"
                  fab
                  type="email"
                  v-model="userData.email"
                ></v-text-field>
              </div>

              <div class="col-6">
                <span>User Mobile</span>
                <v-text-field
                  class="mt-4"
                  v-model="userData.mobile"
                  fab
                  type="tel"
                ></v-text-field>
              </div>
            </div>

            <div class="row d-flex align-items-center">
              <div class="col-12">
                <span>Select Distributor</span>
                <v-autocomplete
                  deletable-chips
                  multiple
                  :menu-props="{
                    closeOnClick: true,
                    closeOnContentClick: true,
                  }"
                  small-chips
                  return-object
                  v-model="userData.distributors"
                  item-text="name"
                  :items="distributorsList"
                ></v-autocomplete>
              </div>
            </div>

            <div class="row d-flex align-items-center">
              <div class="col-12">
                <span>Select Route</span>
                <v-autocomplete
                  deletable-chips
                  multiple
                  :menu-props="{
                    closeOnClick: true,
                    closeOnContentClick: true,
                  }"
                  small-chips
                  item-text="name"
                  return-object
                  v-model="userData.routes"
                  :items="routeList"
                ></v-autocomplete>
              </div>
            </div>

            <div class="row d-flex align-items-center">
              <div class="col-6">
                <span>User Name</span>
                <v-text-field
                  class="mt-4"
                  v-model="userData.username"
                  fab
                  type="text"
                ></v-text-field>
              </div>
              <div class="col-6">
                <span>Password</span>
                <v-text-field
                  class="mt-4"
                  v-model="userData.password"
                  fab
                  type="text"
                ></v-text-field>
              </div>
            </div>
          </v-card>

          <v-card-actions justify="end">
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click.native="close()">
              Close
            </v-btn>
            <v-btn color="blue darken-1" text @click.native="saveUser()">
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>
<script>
export default {
  data() {
    return {
      userData: {
        employee_code: "",
        email: "",
        mobile: "",
        username: "",
        password: "",
        routes: [],
        distributors: [],
      },
    };
  },
  methods: {
    close() {
      this.$emit("update:dialog", false);
    },
    saveUser() {
      let distributors = this.userData.distributors.map((item) => item.id);
      let routes = this.userData.routes.map((item) => item.id);

      let payload = {
        employee_code: this.userData.employee_code,
        email: this.userData.email,
        username: this.userData.username,
        password: this.userData.password,
        mobile: this.userData.mobile,
        distributors: distributors,
        routes: routes,
      };

      let error = undefined;

      if (payload.password?.trim() === "" || payload.password === undefined) {
        error = "Please enter a valid password";
      } else if (payload.username.trim() === "") {
        error = "Please enter a valid username";
      } else if (payload.email.trim() === "") {
        error = "Please enter a valid email address";
      } else if (payload.mobile.trim() === "") {
        error = "Please enter a valid mobile number";
      }

      if (error) {
        this.$notify({
          title: "Error",
          icon: "fa fa-exclamation-triangle",
          type: "danger",
          message: error,
        });
        return;
      }

      this.$store
        .dispatch("setUserDetails", {
          payload,
        })
        .then(() => this.close())
        .catch((err) => {
          this.$notify({
            title: "Failed to add user",
            icon: "fa fa-exclamation-triangle",
            type: "danger",
            message: err.toString(),
          });
        });
    },
    fetchDistributorList() {
      this.$store.dispatch("fetchDistributorList").catch((err) => {
        this.$notify({
          title: "Failed to fetch distributors list",
          icon: "fa fa-exclamation-triangle",
          type: "danger",
          message: err.toString(),
        });
      });
    },
    fetchRouteList() {
      this.$store.dispatch("fetchRouteList").catch((err) => {
        this.$notify({
          title: "Failed to fetch route list",
          icon: "fa fa-exclamation-triangle",
          type: "danger",
          message: err.toString(),
        });
      });
    },
  },
  computed: {
    distributorsList() {
      return this.$store.state.distributorStore.distributors;
    },
    routeList() {
      return this.$store.state.routeStore.routes;
    },
  },
  props: {
    dialog: {
      default: false,
    },
  },
  watch: {
    dialog() {
      this.staffData.username = "";
      this.staffData.email = "";
      this.staffData.mobile = "";
      this.staffData.password = "";
      this.staffData.routes = [];
      this.staffData.distributors = [];
      this.fetchDistributorList();
      this.fetchRouteList();
    },
  },
};
</script>
