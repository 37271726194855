<template>
  <div>
    <base-header class="header pb-8 pt-7 pt-lg-8 d-flex align-items-center">
      <!-- Mask -->
      <!-- <span class="mask bg-gradient-teal opacity-8"></span> -->
      <span class="mask bg-gradient-test opacity-8"></span>
      <!-- Header container -->
      <div class="container-fluid align-items-center">
        <div class="row">
          <div class="col">
            <h1 class="display-2 text-white">Profile</h1>
            <div class="d-flex justify-content-between">
              <p class="text-white mt-0 mb-0">Your profile information</p>
              <!-- <base-button
								@click.prevent="reset = !reset"
								class="px-3 py-1 text-white"
								type="base"
								size="sm"
								>Reset password</base-button
							> -->
            </div>

            <!-- <a href="#!" class="btn btn-info">Edit profile</a> -->
          </div>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col">
          <card shadow type="secondary">
            <div slot="header" class="bg-white border-0">
              <div class="row align-items-center">
                <div class="col-8">
                  <h3 class="mb-0">My account</h3>
                </div>
                <div class="col-4 text-right">
                  <img
                    :src="$store.state.accountData.profilePicture"
                    class="rounded-circle"
                    width="50px"
                  />
                </div>
              </div>
            </div>
            <template>
              <form @submit.prevent>
                <h6 class="heading-small text-muted mb-4">User information</h6>
                <div class="pl-lg-4">
                  <div class="row">
                    <div class="col-lg-6">
                      <base-input
                        alternative=""
                        label="Username"
                        input-classes="form-control-alternative"
                        v-model="$store.state.accountData.username"
                        :disabled="true"
                      />
                    </div>
                    <div class="col-lg-6">
                      <base-input
                        alternative=""
                        label="User ID"
                        input-classes="form-control-alternative"
                        v-model="$store.state.accountData.userID"
                        :disabled="true"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6">
                      <base-input
                        alternative=""
                        label="Name"
                        input-classes="form-control-alternative"
                        v-model="$store.state.profileData.name"
                        :disabled="true"
                      />
                    </div>
                    <div class="col-lg-6">
                      <base-input
                        alternative=""
                        label="User type"
                        input-classes="form-control-alternative"
                        v-model="$store.state.profileData.usertype"
                        :disabled="true"
                      />
                    </div>
                  </div>
                </div>
                <hr class="my-4" />
                <h6 class="heading-small text-muted mb-4">
                  Contact information
                </h6>
                <div class="pl-lg-4">
                  <div class="row">
                    <div class="col-md-12">
                      <base-input
                        alternative=""
                        label="Address"
                        input-classes="form-control-alternative"
                        v-model="$store.state.profileData.address"
                        :disabled="true"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-4">
                      <base-input
                        alternative=""
                        label="Email ID"
                        input-classes="form-control-alternative"
                        v-model="$store.state.profileData.email"
                        :disabled="true"
                      />
                    </div>
                    <div class="col-lg-4">
                      <base-input
                        alternative=""
                        label="Mobile number"
                        input-classes="form-control-alternative"
                        v-model="$store.state.profileData.mobile_no"
                        :disabled="true"
                      />
                    </div>
                    <div class="col-lg-4">
                      <base-input
                        alternative=""
                        label="DOB"
                        input-classes="form-control-alternative"
                        v-model="$store.state.profileData.dob"
                        :disabled="true"
                      />
                    </div>
                  </div>
                </div>
              </form>
            </template>
          </card>
        </div>
      </div>
    </div>
    <!-- reset modal -->
    <modal
      :show="reset"
      @close="closeModal"
      body-classes="p-0"
      modal-classes="modal-dialog-centered modal-sm"
    >
      <card
        type="secondary"
        shadow
        header-classes="bg-white"
        body-classes="px-lg-5 "
        class="border-0"
      >
        <template v-slot:header>
          <div class="text-muted text-center mb-1">
            <h3 class="text-muted">Reset password</h3>
          </div>
        </template>
        <form role="form">
          <base-input
            formClasses="input-group-alternative mb-3"
            type="password"
            v-model="temp_password"
            placeholder="New Password"
            addon-left-icon="ni ni-lock-circle-open"
          >
          </base-input>
          <base-input
            formClasses="input-group-alternative mb-3"
            type="password"
            v-model="userData.password"
            placeholder="Re-type Password"
            addon-left-icon="ni ni-lock-circle-open"
          >
          </base-input>

          <div class="text-center">
            <base-button
              @click.prevent="reset = !reset"
              size="sm"
              type="danger"
              outline
              class="my-2"
              >Cancel</base-button
            >
            <base-button
              @click.prevent="passwordSave"
              size="sm"
              type="success"
              class="my-2"
              >Cofirm</base-button
            >
          </div>
        </form>
      </card>
    </modal>
  </div>
</template>
<script>
export default {
  name: "user-profile",
  methods: {
    passwordSave() {
      var error = undefined;
      if (this.temp_password == "") {
        error = "Enter new password";
      } else if (this.userData.password == "") {
        error = "Re-type new  password";
      } else if (this.userData.password != this.temp_password) {
        error = "The passwords entered don't match each other";
      }
      if (error) {
        this.$notify({
          title: "Error",
          icon: "fa fa-exclamation-triangle",
          type: "danger",
          message: error,
        });
        return;
      }
      this.$store
        .dispatch("resetPassword", this.userData)
        .then(() => {
          this.reset = false;
          this.$store.dispatch("logout");
          this.$store.commit("logout");
          this.$notify({
            title:
              "Password reset successful.Login with the new credentials to continue..",
            icon: "fas fa-thumbs-up",
            type: "success",
          });
          this.$router.push("/login");
        })
        .catch((err) => {
          this.$notify({
            title: "Failed to change password",
            icon: "fa fa-exclamation-triangle",
            type: "danger",
            message: err.toString(),
          });
        });
    },
    closeModal() {
      this.reset = !this.reset;
    },
  },
  data() {
    return {
      reset: false,
      userData: { password: "" },
      temp_password: "",
    };
  },
  mounted() {},
};
</script>
<style></style>
