<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <side-bar
      :background-color="sidebarBackground"
      short-title="Opus"
      title="Opus"
    >
      <template slot="links">
        <sidebar-item
          v-if="userType === 'admin'"
          :link="{
            name: 'Products',
            icon: 'mdi mdi-package mdi-18px text-white',
            path: '/products',
          }"
        />

        <sidebar-item
          v-if="userType === 'admin'"
          :link="{
            name: 'Routes',
            icon: 'mdi mdi-road mdi-18px text-white',
            path: '/routes',
          }"
        />

        <sidebar-item
          v-if="userType === 'admin'"
          :link="{
            name: 'Distributors',
            icon: 'mdi mdi-account-group mdi-18px text-white',
            path: '/distributors',
          }"
        />

        <sidebar-item
          v-if="userType === 'admin'"
          :link="{
            name: 'ASM',
            icon: 'mdi mdi-account mdi-18px text-white',
            path: '/asm',
          }"
        />

        <sidebar-item
          v-if="userType === 'admin'"
          :link="{
            name: 'Shops',
            icon: 'mdi mdi-store-settings mdi-18px text-white',
            path: '/shops',
          }"
        />

        <sidebar-item
          :link="{
            name: 'Staff',
            icon: 'mdi mdi-account-circle mdi-18px text-white',
            path: '/staff-management',
          }"
        />

        <sidebar-item
          v-if="userType === 'admin'"
          :link="{
            name: 'Users',
            icon: 'mdi mdi-account-circle mdi-18px text-white',
            path: '/user-management',
          }"
        />

        <sidebar-item
          v-if="userType === 'admin'"
          :link="{
            name: 'Messages',
            icon: 'mdi mdi-mail mdi-18px text-white',
            path: '/messages',
          }"
        />

        <sidebar-item
          v-if="userType === 'admin'"
          :link="{
            name: 'Reports',
            icon: 'mdi mdi-chart-areaspline-variant mdi-18px text-white',
            path: '/reports',
          }"
        >
          <sidebar-item
            :link="{
              name: 'Staff/Order Report',
              icon: 'mdi mdi-chart-multiline mdi-18px text-white',
              path: '/staff-report',
            }"
          />
          <sidebar-item
            :link="{
              name: 'Location Report',
              icon: 'mdi mdi-chart-tree mdi-18px text-white',
              path: '/location-report',
            }"
          />
          <sidebar-item
            :link="{
              name: 'Staff Activity Report',
              icon: 'mdi mdi-chart-bar mdi-18px text-white',
              path: '/staff-activity-report',
            }"
          />
          <sidebar-item
            :link="{
              name: 'Distributer Report',
              icon: 'mdi mdi-finance mdi-18px text-white',
              path: '/distributer-report',
            }"
          />
          <sidebar-item
            :link="{
              name: 'Shop Report',
              icon: 'mdi mdi-chart-sankey mdi-18px text-white',
              path: '/shop-report',
            }"
          />
          <sidebar-item
            :link="{
              name: 'My Plan Report',
              icon: 'mdi mdi-chart-scatter-plot mdi-18px text-white',
              path: '/my-plan-report',
            }"
          />
          <sidebar-item
            :link="{
              name: 'ASM Report',
              icon: 'mdi mdi-chart-timeline mdi-18px text-white',
              path: '/asm-report',
            }"
          />
          <!-- <sidebar-item
            :link="{
              name: 'DSM Report',
              icon: 'mdi mdi-chart-timeline mdi-18px text-white',
              path: '/dsm-report',
            }"
          /> -->
        </sidebar-item>
        <sidebar-item
          v-if="userType !== 'admin'"
          :link="{
            name: 'Staff/Order Report',
            icon: 'mdi mdi-chart-multiline mdi-18px text-white',
            path: '/staff-report',
          }"
        />
        <sidebar-item
          v-if="userType !== 'admin'"
          :link="{
            name: 'Location Report',
            icon: 'mdi mdi-chart-tree mdi-18px text-white',
            path: '/location-report',
          }"
        />
        <sidebar-item
          v-if="userType !== 'admin'"
          :link="{
            name: 'Staff Activity Report',
            icon: 'mdi mdi-chart-bar mdi-18px text-white',
            path: '/staff-activity-report',
          }"
        />
        <sidebar-item
          v-if="userType !== 'admin'"
          :link="{
            name: 'Distributer Report',
            icon: 'mdi mdi-finance mdi-18px text-white',
            path: '/distributer-report',
          }"
        />
        <sidebar-item
          v-if="userType !== 'admin'"
          :link="{
            name: 'Shop Report',
            icon: 'mdi mdi-chart-sankey mdi-18px text-white',
            path: '/shop-report',
          }"
        />
        <sidebar-item
          v-if="userType !== 'admin'"
          :link="{
            name: 'My Plan Report',
            icon: 'mdi mdi-chart-scatter-plot mdi-18px text-white',
            path: '/my-plan-report',
          }"
        />
      </template>
    </side-bar>

    <div class="main-content" :data="sidebarBackground">
      <dashboard-navbar></dashboard-navbar>

      <div @click="toggleSidebar">
        <loader />
        <fade-transition :duration="200" origin="center top" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </fade-transition>
        <content-footer v-if="!$route.meta.hideFooter"></content-footer>
      </div>
    </div>
  </div>
</template>
<script>
import DashboardNavbar from "./DashboardNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import { FadeTransition } from "vue2-transitions";

export default {
  components: {
    DashboardNavbar,
    ContentFooter,
    FadeTransition,
  },
  data() {
    return {
      sidebarBackground: "vue", //vue|blue|orange|green|red|primary
    };
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
  },
  computed: {
    loader() {
      return this.$store.state.displayLoader;
    },
    userType() {
      return localStorage.getItem("user-type");
    },
  },
};
</script>
<style scoped>
/* .loader-item {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1060;
} */
</style>
