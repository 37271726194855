<template>
  <base-nav
    class="navbar-top navbar-dark"
    id="navbar-main"
    :show-toggle-button="false"
    expand
  >
    <form
      class="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto"
    ></form>
    <ul class="navbar-nav align-items-center d-none d-md-flex">
      <!-- <li class="nav-item dropdown">
			<notification-panel></notification-panel>

		</li> -->
      <li class="nav-item dropdown">
        <base-dropdown class="nav-link pr-0 pl-0" position="right">
          <a
            slot="title"
            class="media align-items-center nav-link"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <span class="avatar avatar-sm rounded-circle">
              <img
                alt="Image placeholder"
                :src="$store.state.accountData.profilePicture"
              />
            </span>
            <div class="media-body ml-2 d-none d-lg-block">
              <span class="mb-0 text-sm text-dark font-weight-bold">{{
                username
              }}</span>
            </div>
          </a>

          <mini-nav></mini-nav>
        </base-dropdown>
      </li>
    </ul>
  </base-nav>
</template>
<script>
import MiniNav from "./MiniNav.vue";

export default {
  components: {
    MiniNav,
  },
  data() {
    return {
      searchSuggestions: [
        {
          title: "Dashboard",
          subTitle: "See all info at a glance",
          keywords: "dashboard home",
          toPage: "/dashboard",
        },
        {
          title: "Profile",
          subTitle: "Have a look at your profile information",
          keywords: "profile account",
          toPage: "/profile",
        },
        {
          title: "Contact",
          subTitle: "Contact us",
          keywords: "contact help support",
          toPage: "/contact",
        },
      ],
      showSearchMenu: false,
      searchQuery: "",
    };
  },
  computed: {
    filteredSuggestions() {
      var ctx = this;
      var searchQuery = this.searchQuery.trim().toLowerCase();
      return this.searchSuggestions.filter((suggestion) => {
        return (
          suggestion.keywords.indexOf(searchQuery) >= 0 &&
          suggestion.toPage != ctx.$route.path
        );
      });
    },
    username() {
      return localStorage.getItem("username");
    },
  },
  methods: {
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    goToPage(page) {
      this.searchQuery = "";
      this.showSearchMenu = false;
      this.$router.push(page);
    },
    closeSearch() {
      this.showSearchMenu = false;
    },
  },
};
</script>
<style scoped>
.suggestions-box {
  box-shadow: 0px 5px 21px 6px #a5a5a5;
  z-index: 1000;
  top: 120%;
  font-size: 14px;
}

.suggestion-box {
  background-color: #f7f7f7;
  transition: 0.2s background-color;
}

.suggestion-box:hover {
  background-color: #e4e4e4;
}

.search-scroller {
  overflow: auto;
  max-height: 300px;
}
</style>
