<template>
  <div class="text-center">
    <h1 class="mt-2">404 Not Found!</h1>
    <a href="" @click.prevent.stop="$router.go(-1)">Go back</a><br />
    <router-link to="/products">Home</router-link>
  </div>
</template>

<script>
export default {
  name: "not-found",
};
</script>

<style scoped>
div {
  font-size: 1.5rem !important;
}

h1 {
  font-size: 1.5em !important;
  color: var(--border);
}
</style>
