var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper",class:{ 'nav-open': _vm.$sidebar.showSidebar }},[_c('side-bar',{attrs:{"background-color":_vm.sidebarBackground,"short-title":"Opus","title":"Opus"}},[_c('template',{slot:"links"},[(_vm.userType === 'admin')?_c('sidebar-item',{attrs:{"link":{
          name: 'Products',
          icon: 'mdi mdi-package mdi-18px text-white',
          path: '/products',
        }}}):_vm._e(),(_vm.userType === 'admin')?_c('sidebar-item',{attrs:{"link":{
          name: 'Routes',
          icon: 'mdi mdi-road mdi-18px text-white',
          path: '/routes',
        }}}):_vm._e(),(_vm.userType === 'admin')?_c('sidebar-item',{attrs:{"link":{
          name: 'Distributors',
          icon: 'mdi mdi-account-group mdi-18px text-white',
          path: '/distributors',
        }}}):_vm._e(),(_vm.userType === 'admin')?_c('sidebar-item',{attrs:{"link":{
          name: 'ASM',
          icon: 'mdi mdi-account mdi-18px text-white',
          path: '/asm',
        }}}):_vm._e(),(_vm.userType === 'admin')?_c('sidebar-item',{attrs:{"link":{
          name: 'Shops',
          icon: 'mdi mdi-store-settings mdi-18px text-white',
          path: '/shops',
        }}}):_vm._e(),_c('sidebar-item',{attrs:{"link":{
          name: 'Staff',
          icon: 'mdi mdi-account-circle mdi-18px text-white',
          path: '/staff-management',
        }}}),(_vm.userType === 'admin')?_c('sidebar-item',{attrs:{"link":{
          name: 'Users',
          icon: 'mdi mdi-account-circle mdi-18px text-white',
          path: '/user-management',
        }}}):_vm._e(),(_vm.userType === 'admin')?_c('sidebar-item',{attrs:{"link":{
          name: 'Messages',
          icon: 'mdi mdi-mail mdi-18px text-white',
          path: '/messages',
        }}}):_vm._e(),(_vm.userType === 'admin')?_c('sidebar-item',{attrs:{"link":{
          name: 'Reports',
          icon: 'mdi mdi-chart-areaspline-variant mdi-18px text-white',
          path: '/reports',
        }}},[_c('sidebar-item',{attrs:{"link":{
            name: 'Staff/Order Report',
            icon: 'mdi mdi-chart-multiline mdi-18px text-white',
            path: '/staff-report',
          }}}),_c('sidebar-item',{attrs:{"link":{
            name: 'Location Report',
            icon: 'mdi mdi-chart-tree mdi-18px text-white',
            path: '/location-report',
          }}}),_c('sidebar-item',{attrs:{"link":{
            name: 'Staff Activity Report',
            icon: 'mdi mdi-chart-bar mdi-18px text-white',
            path: '/staff-activity-report',
          }}}),_c('sidebar-item',{attrs:{"link":{
            name: 'Distributer Report',
            icon: 'mdi mdi-finance mdi-18px text-white',
            path: '/distributer-report',
          }}}),_c('sidebar-item',{attrs:{"link":{
            name: 'Shop Report',
            icon: 'mdi mdi-chart-sankey mdi-18px text-white',
            path: '/shop-report',
          }}}),_c('sidebar-item',{attrs:{"link":{
            name: 'My Plan Report',
            icon: 'mdi mdi-chart-scatter-plot mdi-18px text-white',
            path: '/my-plan-report',
          }}}),_c('sidebar-item',{attrs:{"link":{
            name: 'ASM Report',
            icon: 'mdi mdi-chart-timeline mdi-18px text-white',
            path: '/asm-report',
          }}})],1):_vm._e(),(_vm.userType !== 'admin')?_c('sidebar-item',{attrs:{"link":{
          name: 'Staff/Order Report',
          icon: 'mdi mdi-chart-multiline mdi-18px text-white',
          path: '/staff-report',
        }}}):_vm._e(),(_vm.userType !== 'admin')?_c('sidebar-item',{attrs:{"link":{
          name: 'Location Report',
          icon: 'mdi mdi-chart-tree mdi-18px text-white',
          path: '/location-report',
        }}}):_vm._e(),(_vm.userType !== 'admin')?_c('sidebar-item',{attrs:{"link":{
          name: 'Staff Activity Report',
          icon: 'mdi mdi-chart-bar mdi-18px text-white',
          path: '/staff-activity-report',
        }}}):_vm._e(),(_vm.userType !== 'admin')?_c('sidebar-item',{attrs:{"link":{
          name: 'Distributer Report',
          icon: 'mdi mdi-finance mdi-18px text-white',
          path: '/distributer-report',
        }}}):_vm._e(),(_vm.userType !== 'admin')?_c('sidebar-item',{attrs:{"link":{
          name: 'Shop Report',
          icon: 'mdi mdi-chart-sankey mdi-18px text-white',
          path: '/shop-report',
        }}}):_vm._e(),(_vm.userType !== 'admin')?_c('sidebar-item',{attrs:{"link":{
          name: 'My Plan Report',
          icon: 'mdi mdi-chart-scatter-plot mdi-18px text-white',
          path: '/my-plan-report',
        }}}):_vm._e()],1)],2),_c('div',{staticClass:"main-content",attrs:{"data":_vm.sidebarBackground}},[_c('dashboard-navbar'),_c('div',{on:{"click":_vm.toggleSidebar}},[_c('loader'),_c('fade-transition',{attrs:{"duration":200,"origin":"center top","mode":"out-in"}},[_c('router-view')],1),(!_vm.$route.meta.hideFooter)?_c('content-footer'):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }